@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Tangerine:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Great+Vibes");


.container{
  margin: 10px 120px;
  border-radius: 90px;
  color: inherit;
  background-color: #EFE5FD;
  color:#76727D;
  text-align: center;
}

.Head{
  font-size: 72px;
  letter-spacing: 2px;
  padding: 17px;
  font-family: "Great Vibes", sans-serif;
  margin-bottom:0px ;
}

.Head2{
  margin-bottom: 15px;
  margin-top: 12px;
  font-size: 20px;
  letter-spacing: 2px;
}

.cakeDisplay{
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.cake{
  border: 2px solid #C39AFD;
  background-color: #EFE5FD;
  color: #76727D;
  font-family: "Poppins", sans-serif;
  text-align: center;
  background-clip: border-box;
  border-radius: .25rem;
  margin: 8px 10px;
}

.cakeName{
  margin-bottom: 10px;
  padding:5px 10px;
}